.primaryButton {
  background-color: var(--cardBackgroundColor);
  color: var(--fontColor);
  justify-content: center;
  width: 110px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  padding: 5px 10px;
  margin: 4px;
  border: 1px solid var(--borderColors);
}

.primaryButton:hover {
  background-color: var(--HoverOver);
}

.primaryButtonColor {
  color: var(--white);
  background-color: var(--primaryColor);
}

.primaryButtonColor:hover {
  background-color: var(--primaryColorHover);
}

.text75Primary {
  color: rgba(var(--primaryColorGradient), 0.75);
}

.textBlack {
  color: #1f1f1f;
}

.backgroundGreyNoBorder {
  background-color: var(--tagPickerInputBackgroundColor);
  border-color: var(--tagPickerInputBackgroundColor);
}

.flat {
  height: 30px;
  line-height: 10px;
}

.backgroundRedBig {
  background-color: red;
  border-color: red;
  color: white;
  width: 280px;
  font-size: x-large;
}

.backgroundRedBig:hover {
  background-color: pink;
  border-color: pink;
}

.fullWidthTan {
  background-color: var(--primaryTanColor);
  border-color: var(--primaryTanColor);
  width: 100%;
}

.fullWidthTan:hover {
  background-color: var(--primaryTanHover);
  border-color: var(--primaryTanHover);
}
